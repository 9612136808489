<template>
   <div class="py-2 grid col-span-1">
        <div class="flex items-center">
            <div class="p-1 inline-block uppercase rounded-full mr-3" style="background-color: #F7C4C5">
            <img style="width: auto; max-width: 18px" :src=musicHeart />
            </div>
            <div>
            <h4 class="text-gray-600">{{headline}}</h4>
            </div>
        </div>
    </div>
</template>
<script>
import MusicHeart from "@/assets/img/icons/music_heart.png";

export default {
    props: {
        headline: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            musicHeart: MusicHeart
        }
    },
  methods: {
  }
}
</script>
