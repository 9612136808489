<template>
  <div>
    <navbar-component></navbar-component>
    <main>
      <div
        class="heading relative pt-16 pb-32 flex content-center items-center justify-center"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="`background-image: url(${backgroundImage});`"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75"
              style="background-color: #BBD5D7"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="mt-16 md:mt-0 text-gray-800 font-semibold headline leading-tight leading-tight md:leading-normal">
                  Music for Everyone
                </h1>
                <p class="mt-4 text-xlg text-gray-900 subhead">
                  Transform your passion into mastery with expert music lessons from McKinney Music Studios.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
          style="height: 70px;"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <section class="pb-20 -mt-24"
       
        >
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full"
                    style="background-color: #F7C4C5"
                  >
                    <img :src=inPerson />
                  </div>
                  <h6 class="text-xl font-semibold">Studio Lessons</h6>
                  <p class="mt-2 mb-4 text-gray-600">
                     Experience the joy of learning and practicing music with our instructors at our studio!
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full"
                    style="background-color: #F7C4C5"
                  >
                    <img :src=virtual />
                  </div>
                  <h6 class="text-xl font-semibold">Virtual Lessons</h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    Learn and play from the comfort of your own home with our virtual lessons!
                  </p>
                </div>
              </div>
            </div>
            <div class="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full"
                    style="background-color: #F7C4C5"
                  >
                    <img :src=yourHouse />
                  </div>
                  <h6 class="text-xl font-semibold">We Come To You</h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    Enjoy the benefits of personalized instruction in the comfort of your own space.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-center lg:mt-32">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full"
                style="background-color: #F7C4C5"
              >
                <img :src=musicHeart />
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                Embrace the Universal <br/>Language of Music.
              </h3>
              <p
                class="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700"
              >
               We believe every student deserves the opportunity to experience music, no matter their goals or learning style.
               </p>

              <p
                class="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700"
              >
                As music educators with backgrounds in the allied health profession of music therapy, we make sure to tailor each lesson to the student. There’s not a one-size-fits-all approach to learning and we believe that goes for music, too. 
              </p>
            </div>
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg blue-bg"
              >
                <div
                  class="bg-cover align-middle rounded-t-lg bg-center min-h-3"
                  :style="`height: auto; min-height:400px; width: 100%; background-image: url(${pianoStudent});`">
                </div>
                <blockquote class="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      class="absolute left-0 w-full block"
                      style="height: 95px; top: -94px;"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        class="orange-poly fill-current"
                      ></polygon>
                    </svg>
                    <p class="text-md font-dark mt-2 text-gray-800">
                      “Playing the violin has taught me discipline, focus, and it’s helped broaden my skill set—it’s given me skills I wouldn’t have acquired otherwise. Those skills I’ve learned, are beneficial to anyone no matter what their career path.”
                    </p>
                  </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="relative py-20">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px;"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="Emily and Mark McKinney"
                class="max-w-full rounded-lg shadow-lg"
                :src="mckinneyDuet"
              />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div class="md:pr-12 mt-6 lg:mt-0">
                <h3 class=" text-3xl font-semibold">Discover a Symphony of Instruments: </h3>
                <p class="my-4 text-lg leading-relaxed text-gray-600">
                  Explore Our Music Teacher's Diverse Instrumental Offerings!
                </p>
                <div class="grid mt-5" style="grid-template-columns: repeat(2, 1fr)">
                  <offering-component headline="Violin"></offering-component>

                  <offering-component headline="Viola"></offering-component>

                  <offering-component headline="Cello"></offering-component>

                  <offering-component headline="Piano"></offering-component>

                  <offering-component headline="Guitar"></offering-component>

                  <offering-component headline="Voice"></offering-component>

                  <offering-component headline="Ukulele"></offering-component>

                  <offering-component headline="Mandolin"></offering-component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <section class="pt-20 pb-48">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-24">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold">Here are our heroes</h2>
              <p class="text-lg leading-relaxed m-4 text-gray-600">
                According to the National Oceanic and Atmospheric
                Administration, Ted, Scambos, NSIDClead scentist, puts the
                potentially record maximum.
              </p>
            </div>
          </div> -->
          <!-- <div class="flex flex-wrap">
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/img/team-1-800x800.jpg"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Ryan Tompson</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    Web Developer
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-twitter"></i></button
                    ><button
                      class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-facebook-f"></i></button
                    ><button
                      class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-dribbble"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/img/team-2-800x800.jpg"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Romina Hadid</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    Marketing Specialist
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-google"></i></button
                    ><button
                      class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/img/team-3-800x800.jpg"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Alexa Smith</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    UI/UX Designer
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-google"></i></button
                    ><button
                      class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-twitter"></i></button
                    ><button
                      class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-instagram"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
              <div class="px-6">
                <img
                  alt="..."
                  src="../assets/img/team-4-470x470.png"
                  class="shadow-lg rounded-full max-w-full mx-auto"
                  style="max-width: 120px;"
                />
                <div class="pt-6 text-center">
                  <h5 class="text-xl font-bold">Jenna Kardi</h5>
                  <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                    Founder and CEO
                  </p>
                  <div class="mt-6">
                    <button
                      class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-dribbble"></i></button
                    ><button
                      class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-google"></i></button
                    ><button
                      class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-twitter"></i></button
                    ><button
                      class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                    >
                      <i class="fab fa-instagram"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        <!-- </div>
      </section> -->
      <section class="pb-20 relative block" style="background-color: #BBD5D7">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style="height: 80px;"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="blue-poly fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <p class=" mt-10 text-lg leading-relaxed mt-4 mb-4 text-gray-500">
               “I studied piano and violin with Mark and Emily for three years. They were terrific teachers who helped me build a passion for music. They taught me how to appreciate the art of music and performance. I’m grateful for their commitment to my musical eduction. Their feedback was very encouraging. They’re great mentors and friends who have had a positive impact on my life.”
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="relative block py-24 lg:pt-0 blue-bg">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div
                class="mt-10 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg gray-bg"
              >
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">Ready to Harmonize Your Musical Journey?</h4>
                  <p class="leading-relaxed mt-1 mb-4 text-gray-600">
                    Contact Us Today and Unlock Your Potential!
                  </p>
                  <div v-if="formErrors" class="flex flex-wrap text-center justify-center">
                    <div class="w-full lg:w-6/12 px-4">
                      <p class=" mt-10 text-lg leading-relaxed mt-4 mb-4 text-red-500">
                        There was a problem submitting your form. Please check that all fields are filled out correctly and try again. If you are still having trouble, please contact us at 417-718-4470.
                      </p>
                    </div>
                  </div>
                  <form v-if="!formSubmitted" id="my-form" @submit.prevent="submitForm" action="https://formspree.io/f/xwkjjzqr" method="POST">
                    <div class="relative w-full mb-3 mt-8">
                      <label
                        class="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="full-name"
                        >Full Name</label
                      ><input
                        v-model="form.name"
                        name="name"
                        type="text"
                        class="border border-gray-300 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Full Name"
                        style="transition: all 0.15s ease 0s;"
                      />
                    </div>
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="email"
                        >Email</label
                      ><input
                        v-model="form.email"
                        name="email"
                        type="email"
                        class="border border-gray-300 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Email"
                        style="transition: all 0.15s ease 0s;"
                      />
                    </div>
                    <div class="relative w-full mb-3 pr-7"
                      style="width: 100%;">
                      <label
                        class="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="email"
                        >Instrument of Choice</label
                      >
                      <select v-model="form.instrument" name="instrument" class="w-full color-gray-200 appearance-none bg-white border border-gray-300 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:border-blue-500" multiple>
                        <option>Violin</option>
                        <option>Viola</option>
                        <option >Cello</option>
                        <option>Piano</option>
                        <option>Guitar</option>
                        <option>Voice</option>
                        <option>Ukulele</option>
                        <option>Mandolin</option>
                      </select>
                      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                          <path
                            d="M10 12.586L6.707 9.293a1 1 0 011.414-1.414L10 10.172l2.879-2.879a1 1 0 011.414 1.414L10 12.586z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="message"
                        >Message</label
                      ><textarea
                        v-model="form.message"
                        name="message"
                        rows="4"
                        cols="80"
                        class="border border-gray-300 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Tell us about yourself..."
                      ></textarea>
                    </div>
                    <input type="hidden" name="form-name" value="contact">
                    <div class="text-center mt-6">
                      <button
                        type="submit"
                        class="blue-bg text-gray-700 active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                        style="transition: all 0.15s ease 0s;"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                  <div v-else class="flex flex-wrap text-center justify-center">
                    <div class="w-full lg:w-6/12 px-4">
                      <p class=" mt-10 text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                        Thank you for contacting us! We'll get back to your shortly.
                      </p>
                    </div>
                  </div>
                   
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component></footer-component>
  </div>
</template>
<script>
import BackgroundImage from "@/assets/img/playing-violin.jpg";
import MusicHeart from "@/assets/img/icons/music_heart.png";
import PianoIcon from "@/assets/img/icons/piano.png";
import VirtualIcon from "@/assets/img/icons/virtual.png";
import YourHouseIcon from "@/assets/img/icons/your-house.png";
import InPersonIcon from "@/assets/img/icons/in-person.png";
import PianoStudent from "@/assets/img/mckinney_student.jpeg"

import NavbarComponent from "../components/Navbar.vue";
import FooterComponent from "../components/Footer.vue";
import OfferingComponent from "../components/Offering.vue";
import MckinneyDuet from "@/assets/img/mckinney_duet.jpeg";

export default {
  name: "landing-page",
  data() {
    return {
      backgroundImage: BackgroundImage,
      mckinneyDuet: MckinneyDuet,
      musicHeart: MusicHeart,
      piano: PianoIcon,
      virtual: VirtualIcon,
      yourHouse: YourHouseIcon,
      inPerson: InPersonIcon,
      pianoStudent: PianoStudent,
      form: {
        name: '',
        email: '',
        instrument: '',
        message: ''
      },
      formSubmitted: false,
      formErrors: false
    };
  },
  components: {
    NavbarComponent,
    FooterComponent,
    OfferingComponent
  },
  methods: {
    
    submitForm() {
      console.log('form data: ', JSON.stringify(this.form));
      fetch('https://formspree.io/f/xwkjjzqr', {
        method: 'POST',
        body: JSON.stringify(this.form),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .catch(e => {
          console.log('error sending form data: ', e)
        })
        .then(r => r.json())
        .then(response => {
          if (response.ok) {
            console.log('okay response!');
            this.formSubmitted = true;
            this.formErrors = false;
          } else {
            this.formErrors = true;
          }      
        });
    },
    encode(data) {
      return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
    }
  }
}
</script>
